$(() => {
    show_password_control();

    function show_password_control() {
        if ($("#form_password").val() == '') {
            $('.pass').removeClass('password-visible')
        } else {
            $('.pass').addClass('password-visible')
        }
    }

    $('body').on('change keyup', '#form_password', function () {
        show_password_control();
    });
    $('body').on('click', '.password-visible', function () {
        if ($('#form_password').attr('type') == 'password') {
            $(this).addClass('view');
            $('#form_password').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#form_password').attr('type', 'password');
        }
        return false;
    });

});

$(() => {
    show_password_control_2();

    function show_password_control_2() {
        if ($("#create_password").val() == '') {
            $('.pass2').removeClass('password-visible2')
        } else {
            $('.pass2').addClass('password-visible2')
        }
    }

    $('body').on('change keyup', '#create_password', function () {
        show_password_control_2();
    });
    $('body').on('click', '.password-visible2', function () {
        if ($('#create_password').attr('type') == 'password') {
            $(this).addClass('view');
            $('#create_password').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#create_password').attr('type', 'password');
        }
        return false;
    });

});

$(() => {
    show_password_control_3();

    function show_password_control_3() {
        if ($("#confirm_password").val() == '') {
            $('.pass3').removeClass('password-visible3')
        } else {
            $('.pass3').addClass('password-visible3')
        }
    }

    $('body').on('change keyup', '#confirm_password', function () {
        show_password_control_3();
    });
    $('body').on('click', '.password-visible3', function () {
        if ($('#confirm_password').attr('type') == 'password') {
            $(this).addClass('view');
            $('#confirm_password').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#confirm_password').attr('type', 'password');
        }
        return false;
    });

});


$(() => {
    show_password_control_4();

    function show_password_control_4() {
        if ($("#password-account").val() == '') {
            $('.pass4').removeClass('password-visible4')
        } else {
            $('.pass4').addClass('password-visible4')
        }
    }

    $('body').on('change keyup', '#password-account', function () {
        show_password_control_4();
    });
    $('body').on('click', '.password-visible4', function () {
        if ($('#password-account').attr('type') == 'password') {
            $(this).addClass('view');
            $('#password-account').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#password-account').attr('type', 'password');
        }
        return false;
    });

});

$(() => {
    show_password_control_5();

    function show_password_control_5() {
        if ($("#password-profile").val() == '') {
            $('.pass5').removeClass('password-visible5')
        } else {
            $('.pass5').addClass('password-visible5')
        }
    }

    $('body').on('change keyup', '#password-profile', function () {
        show_password_control_5();
    });
    $('body').on('click', '.password-visible5', function () {
        if ($('#password-profile').attr('type') == 'password') {
            $(this).addClass('view');
            $('#password-profile').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#password-profile').attr('type', 'password');
        }
        return false;
    });

});