require('./block/sideBar');
require('./block/password');
require('./block/slider');
require('./block/downloadInput');
require('./block/rolling');
require('./block/slider');
require('./block/video');
require('./block/accordion');
require('./block/lightbox');
require('./block/preloader');
require('./block/charts');




