(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
$(() => {

});
},{}],2:[function(require,module,exports){
$(() => {

    Highcharts.chart('charts', {
        chart: {
            type: 'pie',
            width: 170,
            height: 170,
            custom: {},

            events: {
                render() {
                    const chart = this,
                        series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(

                                '<div class="graphic-title"><strong>$ 50 000</strong></div>'
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle',
                                    border: '1px dashed #fff'
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop -
                            (customLabel.attr('height') / 2);

                    customLabel.attr({
                        x,
                        y
                    });
                    // Set font size based on chart diameter
                    customLabel.css({
                        fontSize: `20px`
                    });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },

        tooltip: {
            enabled: false
        },

        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        plotOptions: {
            pie: {
                size: 163,
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                },
                innerSize: '80%',
            },
        },

        series: [{

            colorByPoint: true,
            enableMouseTracking: false,
            showInLegend: false,
            data: [ {
                name: 'Активы' ,
                y: 17000,

            }, {
                name: 'Команда',
                y: 15000,

            }, {
                name: 'Промо',
                y: 28000,

            }]
        }]
    });

});

$(() => {

    Highcharts.chart('line', {

        chart: {
            type: 'line',
            width: 100,
            height: 50,
        },

        title: {

        },

        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        subtitle: {

        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            visible: false,
        },

        xAxis: {
            visible: false,
        },

        plotOptions: {

            line: {
                size: 100
            },

            series: {
                marker: {
                    enabled: false
                },
                color: '#e28d44',
                enableMouseTracking: false,
                showInLegend: false,
            }
        },


        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        series: [{

        },   {
            name: '',
            data: [
                29.9, 56, 90, 75, 60, 176.0, 135.6, 148.5, 216.4, 194.1,
                95.6, 54.4
            ]
        }],

    });

});

$(() => {

    Highcharts.chart('lineRed', {

        chart: {
            type: 'line',
            width: 100,
            height: 50,
        },

        title: {

        },

        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        subtitle: {

        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            visible: false,
        },

        xAxis: {
            visible: false,
        },

        plotOptions: {

            line: {
                size: 100
            },

            series: {
                marker: {
                    enabled: false
                },
                color: '#FA2256',
                enableMouseTracking: false,
                showInLegend: false,
            }
        },


        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        series: [{

        },   {
            name: '',
            data: [
                29.9, 34, 21, 56, 90, 150, 135.6, 148.5, 110, 170,
                95.6, 54.4
            ]
        }],

    });

});

$(() => {

    Highcharts.chart('lineGreen', {

        chart: {
            type: 'line',
            width: 100,
            height: 50,
        },

        title: {

        },

        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        subtitle: {

        },

        tooltip: {
            enabled: false
        },

        yAxis: {
            visible: false,
        },

        xAxis: {
            visible: false,
        },

        plotOptions: {

            line: {
                size: 100
            },

            series: {
                marker: {
                    enabled: false
                },
                color: '#11CABE',
                enableMouseTracking: false,
                showInLegend: false,
            }
        },


        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        series: [{

        },   {
            name: '',
            data: [
                29.9, 30, 40, 75, 60, 24, 11, 148.5, 200, 194.1,
                95.6, 54.4
            ]
        }],

    });

});

$(() => {

    Highcharts.chart('chartsModal', {
        chart: {
            type: 'pie',
            width: 170,
            height: 170,
            custom: {},

            events: {
                render() {
                    const chart = this,
                        series = chart.series[0];
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(

                                '<div class="graphic-title"><strong>$ 50 000</strong></div>'
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle',
                                    border: '1px dashed #fff'
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop -
                            (customLabel.attr('height') / 2);

                    customLabel.attr({
                        x,
                        y
                    });
                    // Set font size based on chart diameter
                    customLabel.css({
                        fontSize: `20px`
                    });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },

        tooltip: {
            enabled: false
        },

        legend: {
            enabled: false
        },

        zooming:{
            enabled: false
        },

        plotOptions: {
            pie: {
                size: 163,
                borderWidth: 0,
                dataLabels: {
                    enabled: false
                },
                innerSize: '80%',
            },
        },

        series: [{

            colorByPoint: true,
            enableMouseTracking: false,
            showInLegend: false,
            data: [ {
                name: 'Активы' ,
                y: 17000,

            }, {
                name: 'Команда',
                y: 15000,

            }, {
                name: 'Промо',
                y: 28000,

            }]
        }]
    });

});





},{}],3:[function(require,module,exports){
$(() => {
    $('.custom-file').change(function () {
        $(this).addClass('custom-file_success')
    });
});
},{}],4:[function(require,module,exports){
$(() => {
    lightbox.option({
        'resizeDuration': 400,
        'wrapAround': true,
        'alwaysShowNavOnTouchDevices': true,
        'albumLabel': false,
        'disableScrolling': true,
        'showImageNumberLabel': true,
    })
});
},{}],5:[function(require,module,exports){
$(() => {
    show_password_control();

    function show_password_control() {
        if ($("#form_password").val() == '') {
            $('.pass').removeClass('password-visible')
        } else {
            $('.pass').addClass('password-visible')
        }
    }

    $('body').on('change keyup', '#form_password', function () {
        show_password_control();
    });
    $('body').on('click', '.password-visible', function () {
        if ($('#form_password').attr('type') == 'password') {
            $(this).addClass('view');
            $('#form_password').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#form_password').attr('type', 'password');
        }
        return false;
    });

});

$(() => {
    show_password_control_2();

    function show_password_control_2() {
        if ($("#create_password").val() == '') {
            $('.pass2').removeClass('password-visible2')
        } else {
            $('.pass2').addClass('password-visible2')
        }
    }

    $('body').on('change keyup', '#create_password', function () {
        show_password_control_2();
    });
    $('body').on('click', '.password-visible2', function () {
        if ($('#create_password').attr('type') == 'password') {
            $(this).addClass('view');
            $('#create_password').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#create_password').attr('type', 'password');
        }
        return false;
    });

});

$(() => {
    show_password_control_3();

    function show_password_control_3() {
        if ($("#confirm_password").val() == '') {
            $('.pass3').removeClass('password-visible3')
        } else {
            $('.pass3').addClass('password-visible3')
        }
    }

    $('body').on('change keyup', '#confirm_password', function () {
        show_password_control_3();
    });
    $('body').on('click', '.password-visible3', function () {
        if ($('#confirm_password').attr('type') == 'password') {
            $(this).addClass('view');
            $('#confirm_password').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#confirm_password').attr('type', 'password');
        }
        return false;
    });

});


$(() => {
    show_password_control_4();

    function show_password_control_4() {
        if ($("#password-account").val() == '') {
            $('.pass4').removeClass('password-visible4')
        } else {
            $('.pass4').addClass('password-visible4')
        }
    }

    $('body').on('change keyup', '#password-account', function () {
        show_password_control_4();
    });
    $('body').on('click', '.password-visible4', function () {
        if ($('#password-account').attr('type') == 'password') {
            $(this).addClass('view');
            $('#password-account').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#password-account').attr('type', 'password');
        }
        return false;
    });

});

$(() => {
    show_password_control_5();

    function show_password_control_5() {
        if ($("#password-profile").val() == '') {
            $('.pass5').removeClass('password-visible5')
        } else {
            $('.pass5').addClass('password-visible5')
        }
    }

    $('body').on('change keyup', '#password-profile', function () {
        show_password_control_5();
    });
    $('body').on('click', '.password-visible5', function () {
        if ($('#password-profile').attr('type') == 'password') {
            $(this).addClass('view');
            $('#password-profile').attr('type', 'text');
        } else {
            $(this).removeClass('view');
            $('#password-profile').attr('type', 'password');
        }
        return false;
    });

});
},{}],6:[function(require,module,exports){
$(() => {
    let typing = new Typed("#typed", {
        strings: ["ETFRIX"],
        typeSpeed: 160,
        backSpeed: 60,
        backDelay: 1000,
        loop: false,
        smartBackspace: false,
        cursorChar: '_',
        attr: null
    });

});

function loadData() {
    return new Promise((resolve, reject) => {
        // setTimeout не является частью решения
        // Код ниже должен быть заменен на логику подходящую для решения вашей задачи
        setTimeout(resolve, 3000);
    })
}

loadData()
    .then(() => {
        let preloaderEl = document.getElementById('preloader');
        preloaderEl.classList.add('hidden_preloader');
        preloaderEl.classList.remove('visible_preloader');
});
},{}],7:[function(require,module,exports){
$(() => {
    (function($){

        $.fn.makeRollingCaption = function(options) {

            options = $.extend({
                speed: 20
            }, options);

            this.each(function(index, container){

                var methods = {

                    initialize: function() {

                        this.container = $(container).first();
                        this.content = this.container.find('.b-rolling-caption__content');
                        this.wrap = this.container.find('.b-rolling-caption__wrap');

                        this.setWidth();
                        this.wrap.show();

                        var item = this.content.find('span').first();

                        this.start(item);

                        $(window).on('resize', $.proxy(function(){
                            this.content.stop(true, true).css('left', 0);
                            if ( this.timer ) clearTimeout(this.timer); else this.wrap.hide();
                            this.timer = setTimeout($.proxy(this.onStopResize, this), 300);

                        }, this));

                    },

                    setWidth: function() {
                        this.wrap.width(this.container.parent().width());
                    },

                    onStopResize: function() {

                        this.setWidth();

                        this.timer = null;
                        this.wrap.show();
                        this.start(this.content.find('span').first());
                    },

                    start: function(item) {
                        if ( item.length ) {

                            this.content.animate({
                                left: '-=' + item.width() + 'px'
                            }, item.width() * options.speed, 'linear', $.proxy(function(){
                                var next = item.next();
                                if ( !next ) next = this.content.find('span').first();
                                this.content.append(item);
                                if ( !this.timer ) {
                                    this.content.css('left', 0);
                                    this.start(next);
                                }

                            }, this));

                        }
                    }

                };

                methods.initialize();

            });

        };

    })(jQuery);


    $(function(){
        $('#rolling-caption').makeRollingCaption({ speed: 20 });
    });

});
},{}],8:[function(require,module,exports){
$(() => {
	$(() => {
		let obj = $('.sideBar');
		let content = $('.sideBar-content');
		let page = $('body');

		function toggle() {
			obj.toggleClass('sideBar_visible');
			page.toggleClass('noScroll');

		}

		$('.js-sideBarCtrl').click(toggle);
		content.click((e) => {
			e.stopPropagation();
		});
		obj.click(toggle);
	});

	let Menu = {

		el: {
			ham: $('.menu_burger'),
			menuTop: $('.menu-top'),
			menuMiddle: $('.menu-middle'),
			menuBottom: $('.menu-bottom')
		},

		init: function() {
			Menu.bindUIactions();
		},

		bindUIactions: function() {
			Menu.el.ham
				.on(
					'click',
					function(event) {
						Menu.activateMenu(event);
						event.preventDefault();
					}
				);
		},

		activateMenu: function() {
			Menu.el.menuTop.toggleClass('menu-top-click');
			Menu.el.menuMiddle.toggleClass('menu-middle-click');
			Menu.el.menuBottom.toggleClass('menu-bottom-click');
		}
	};

	Menu.init();
});

$(() => {
	let obj = $('.sideBar2');
	let content = $('.sideBar-content2');
	let page = $('body');

	function toggle() {
		obj.toggleClass('sideBar_visible');
		page.toggleClass('noScroll');

	}

	$('.js-sideBarCtrl2').click(toggle);
	content.click((e) => {
		// e.stopPropagation();
	});
	obj.click(toggle);
});
},{}],9:[function(require,module,exports){
$(() => {
    $(".connection-slider").slick({
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: $('.prev-index'),
        nextArrow: $('.next-index'),
        draggable: false,
        variableWidth: true,
        responsive: [

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    draggable: true,
                }
            },

            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    draggable: true,
                }
            }
        ],

    });


    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.slider-nav'
    });

    $('.slider-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        focusOnSelect: false,
        arrows: true,
        variableWidth: true,
        allowTouchMove: false,
        swipe: false,
        responsive: [

            {
                breakpoint: 1415,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    draggable: true,
                }
            },

            {
                breakpoint: 1180,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    draggable: true,
                }
            },

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    draggable: true,
                }
            },

            {
                breakpoint: 462,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    draggable: true,
                }
            },

            {
                breakpoint: 368,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    draggable: true,
                }
            }
        ],

    });


    $(".document-slider").slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $('.prev-index2'),
        nextArrow: $('.next-index2'),
        draggable: false
    });

});
},{}],10:[function(require,module,exports){
$(() => {

    const toggleButton = document.querySelector('.js-toggle');
    const videoPlayer = document.getElementById('fullSizeVideo');
    const textBlock = document.getElementById('text');
    const videoOverlay = document.getElementById('video-overlay');

    const toggleVideo = (event) => {

        event.preventDefault();
        videoPlayer.controls = 'controls';
        videoPlayer.muted = !videoPlayer.muted;
        textBlock.style.display = 'none';
        videoOverlay.style.display = 'none';
        videoPlayer.volume = 0.9;
        videoPlayer.setAttribute("src", "../img/video.mp4");
        videoPlayer.play();
    };

    toggleButton.addEventListener('click', toggleVideo);

});
},{}],11:[function(require,module,exports){
require('./block/sideBar');
require('./block/password');
require('./block/slider');
require('./block/downloadInput');
require('./block/rolling');
require('./block/slider');
require('./block/video');
require('./block/accordion');
require('./block/lightbox');
require('./block/preloader');
require('./block/charts');





},{"./block/accordion":1,"./block/charts":2,"./block/downloadInput":3,"./block/lightbox":4,"./block/password":5,"./block/preloader":6,"./block/rolling":7,"./block/sideBar":8,"./block/slider":9,"./block/video":10}]},{},[11])

